import React from 'react';
import {
  Container,
  Heading,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
} from '@chakra-ui/react';

export default function Contact() {
  return (
    <Container id="contact" maxW="container.md" py={12}>
      <Stack spacing={4}>
        <Heading
          as="h4"
          size="xl"
          mb={12}
          textAlign="center"
          color="brand.primary"
        >
          Contact us
        </Heading>
        <FormControl id="name">
          <FormLabel>Name</FormLabel>
          <Input type="text" placeholder="Your Full Name" />
        </FormControl>
        <FormControl id="email">
          <FormLabel>Email</FormLabel>
          <Input type="email" placeholder="Your Email Address" />
        </FormControl>
        <FormControl id="message">
          <FormLabel>Message</FormLabel>
          <Textarea placeholder="Your Message" />
        </FormControl>
        <Button
          mt={4}
          color={'white'}
          bg={'brand.primary'}
          _hover={{ bg: 'brand.hoverPrimary' }}
          type="submit"
        >
          Send Message
        </Button>
      </Stack>
    </Container>
  );
}
