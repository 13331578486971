import React from 'react';
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Icon,
  useColorModeValue,
  useColorMode,
} from '@chakra-ui/react';
import { FaCode, FaMobileAlt, FaCloud } from 'react-icons/fa';

export default function Service() {
  const { colorMode } = useColorMode();

  const services = [
    {
      title: 'Web Development',
      description:
        'Build scalable and robust web apps with modern technologies.',
      icon: FaCode,
    },
    {
      title: 'Mobile App Development',
      description:
        'Create mobile applications that offer compelling user experiences.',
      icon: FaMobileAlt,
    },
    {
      title: 'Cloud Solutions',
      description:
        'Deploy and manage cloud infrastructure to enhance business efficiency.',
      icon: FaCloud,
    },
  ];

  return (
    <Box
      id="services"
      py={12}
      px={8}
      bg={useColorModeValue('gray.100', 'gray.900')}
    >
      <Heading
        as="h4"
        size="xl"
        mb={12}
        textAlign="center"
        color="brand.primary"
      >
        Our Services
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        {services.map((service, index) => (
          <Box key={index} p={5} shadow="md" borderWidth="1px" rounded="md">
            <Icon
              color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
              as={service.icon}
              w={10}
              h={10}
              mb={4}
            />
            <Heading
              color={colorMode === 'light' ? 'gray.700' : 'gray.300'}
              as="h5"
              size="md"
              mb={2}
            >
              {service.title}
            </Heading>
            <Text color={'gray.500'} fontSize="md">
              {service.description}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
}
