import {
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import filter from '../assets/filter-2.svg';
import Typist from 'react-typist';

export default function About() {
  return (
    <Container maxW={'container.xl'}>
      <Stack direction={{ base: 'column', md: 'row' }}>
        <Flex flex={1}>
          <Image alt={'About Image'} objectFit={'contain'} src={filter} />
        </Flex>
        <Flex p={2} flex={1} align={'center'} justify={'center'}>
          <Stack spacing={1} w={'full'} maxW={'lg'}>
            <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                color={useColorModeValue('gray.700', 'gray.300')}
                fontSize={40}
              >
                Software Solutions
              </Text>
              <br />{' '}
              <Text fontSize={26} color={'brand.primary'} as={'span'}>
                Tailored for Your Business
              </Text>{' '}
            </Heading>
            <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
              <Typist
                cursor={{
                  show: true,
                  blink: true,
                  element: '_',
                  hideWhenDone: true,
                }}
              >
                <p>
                  Our agency provides comprehensive software development
                  services to enhance your business operations.
                </p>
              </Typist>
            </Text>
            <Stack mt={4} direction={{ md: 'row' }} spacing={4}>
              <Button
                rounded={'full'}
                color={'white'}
                bg={'brand.primary'}
                _hover={{ bg: 'brand.hoverPrimary' }}
                onClick={() =>
                  (window.location.href = 'https://wa.me/25776354500')
                } // Example phone number for WhatsApp
              >
                &nbsp;<i className="bi bi-whatsapp"></i>&nbsp;Message Us
              </Button>
              <Button
                rounded={'full'}
                onClick={() => (window.location.href = 'tel:+25762525242')} // Call to +25762525242
              >
                <i className="bi bi-telephone"></i>&nbsp;Call Us
              </Button>
            </Stack>
          </Stack>
        </Flex>
      </Stack>
      <br />
    </Container>
  );
}
