import { Box } from '@chakra-ui/react';
import React from 'react';
import Team from '../components/Team';
import About from '../components/About';
import TestimonialComponent from '../components/Testemonials';
import Service from '../components/Service';
import Navbar from '../components/Navbar';
import Contact from '../components/Contact';

export default function Home() {
  const executeScroll = id => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box>
      <Navbar executeScroll={executeScroll} />

      <br />
      <About />
      <Service />
      <Team />
      <TestimonialComponent id="clients" />
      <Contact />
    </Box>
  );
}
