import {
  Box,
  Flex,
  HStack,
  IconButton,
  Button,
  useColorModeValue,
  Stack,
  Image,
  useColorMode,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';
import logo from '../assets/logo.png';
import { useState } from 'react';

const links = [
  {
    title: 'Services',
    link: 'services',
  },
  {
    title: 'Team',
    link: 'team',
  },
  {
    title: 'Contact',
    link: 'contact',
  },
];

const NavLink = ({ values, executeScroll, handleShow }) => {
  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      cursor={'pointer'}
      onClick={() => {
        executeScroll(values.link);
        handleShow();
      }}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
    >
      {values.title}
    </Box>
  );
};

export default function Navbar({ executeScroll }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <HStack spacing={8} alignItems={'center'}>
            <Box>
              <Image width={125} src={logo} />
            </Box>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}
            >
              {links.map(link => (
                <NavLink
                  key={link.link}
                  values={link}
                  handleShow={handleShow}
                  executeScroll={executeScroll}
                />
              ))}
            </HStack>
          </HStack>

          <Flex alignItems={'right'}>
            <Stack direction={'row'} spacing={7}>
              <Button onClick={toggleColorMode}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              </Button>
            </Stack>
            <IconButton
              ml={4}
              size={'md'}
              icon={show ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={handleShow}
            />
          </Flex>
        </Flex>

        {show ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {links.map(link => (
                <NavLink
                  values={link}
                  handleShow={handleShow}
                  executeScroll={executeScroll}
                  key={link.link}
                />
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
