import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import Home from './pages/Home';
import { BrowserRouter } from 'react-router-dom';
import 'animate.css/animate.compat.css';

// EXTENDED COLORS
const colors = {
  brand: {
    primary: '#6AA3D9',
    hoverPrimary: '#7198CA',
  },
};

const theme = extendTheme({
  colors,

  fonts: {
    heading: '"Fira Code", monospace',
    body: '"Fira Code", monospace',
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Home />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
