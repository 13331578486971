import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from '@chakra-ui/react';

interface Props {
  children: React.ReactNode;
}

const Testimonial = (props: Props) => {
  const { children } = props;

  return <Box>{children}</Box>;
};

const TestimonialContent = (props: Props) => {
  const { children } = props;

  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = (props: Props) => {
  const { children } = props;

  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

const TestimonialText = (props: Props) => {
  const { children } = props;

  return (
    <Text
      textAlign={'center'}
      color={useColorModeValue('gray.600', 'gray.400')}
      fontSize={'sm'}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string,
  name: string,
  title: string,
}) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar src={src} mb={2} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export default function TestimonialComponent() {
  return (
    <Box
      id="clients"
      py={12}
      px={4}
      bg={useColorModeValue('gray.100', 'gray.700')}
    >
      <Container maxW={'7xl'} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading
            as="h4"
            size="xl"
            mb={4}
            textAlign="center"
            color="brand.primary"
          >
            Our Clients Speak
          </Heading>
          <Text textAlign={'center'}>
            We have been working with clients around the world
          </Text>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Efficient Collaboration</TestimonialHeading>
              <TestimonialText>
                "The collaboration on our web application was nothing short of
                remarkable. The team's responsiveness and attention to detail
                made the entire process seamless and highly effective."
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              name={'Jane M'}
              title={'CEO at Safi Deals Inc'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Intuitive Design</TestimonialHeading>
              <TestimonialText>
                "The intuitive design of the platform allowed us to navigate
                seamlessly and efficiently, enhancing our overall workflow."
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              name={'Thomas Brown'}
              title={'CEO at TIKETA INC'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Mindblowing Service</TestimonialHeading>
              <TestimonialText>
                "The service provided was truly exceptional; it was clear,
                concise, and tailored exactly to our needs, exceeding our
                expectations at every turn."
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar name={'Bill James'} title={'CEO at KSO Inc'} />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
}
