import React from 'react';
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  Image,
  useColorModeValue,
  VStack,
  Badge,
  HStack,
  Link,
} from '@chakra-ui/react';

import arsene from '../assets/arsene.jpg';
import muco from '../assets/muco.jpg';
import franck from '../assets/franck.jpg';
import vassilly from '../assets/vassilly.jpg';
import aime from '../assets/aime.jpeg';

// Define the shuffleArray function here
function shuffleArray(array) {
  // Implementation of shuffleArray function
  // return array.sort(() => Math.random() - 0.5);
  return array;
}

export default function Team() {
  const textColor = useColorModeValue('gray.600', 'gray.200');

  const team = [
    {
      name: 'Aimé NDAYIZEYE',
      position: 'Fin Tech Consultant',
      imageUrl: aime,
      // github: 'https://github.com/aimendayizeye',
      linkedIn: 'https://www.linkedin.com/in/ndayizeyeaime/',
      twitter: 'https://twitter.com/aim_ndayizeye',
    },
    {
      name: 'Arsene NDUWAYO',
      position: 'Backend Engineer',
      imageUrl: arsene,
      github: 'https://github.com/arsou2015',
      linkedIn: 'https://www.linkedin.com/in/arsene-nduwayo-a8b68613b/',
      twitter: 'https://twitter.com/arsou257',
      link: 'https://arsenenduwayo.com/',
    },
    {
      name: 'Aurélien REMA',
      position: 'Cybersecurity Engineer',
      imageUrl:
        'https://pics.craiyon.com/2024-02-06/BklOlUL6SRm98vE5ZjFYpA.webp',
      github: 'https://github.com/mirisho',
      linkedIn: 'https://www.linkedin.com/in/aurelien-rema-571746264/',
      twitter: '',
    },
    {
      name: 'Franck NIJIMBERE',
      position: 'AI Engineer',
      imageUrl: franck,
      github: 'https://github.com/nijfranck',
      linkedIn: 'https://www.linkedin.com/in/francknijimbere/',
      twitter: 'https://twitter.com/nijfranck',
      link: 'https://nijfranck.com/',
    },
    {
      name: 'Grâce-Fleur MUCO',
      position: 'Frontend Developer',
      imageUrl: muco,
      // github: 'https://github.com/muco2023',
    },
    {
      name: 'Vassilly IBINKWIYE',
      position: 'Full Stack Developer',
      imageUrl: vassilly,
      github: 'https://github.com/redMulla',
      linkedIn:
        'https://www.linkedin.com/in/vassilly-red-v-ibinkwiye-3884131b9/',
      twitter: 'https://twitter.com/Vassilly3',
      link: 'https://vassilly2025.showwcase.com/',
    },
  ];

  return (
    <Box py={12} id="team">
      <Heading
        as="h4"
        size="xl"
        mb={12}
        textAlign="center"
        color="brand.primary"
      >
        Meet Our Team
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
        {shuffleArray(team).map((val, index) => (
          <VStack key={index} spacing={4} align="center">
            {val.link ? (
              <Link href={val.link} isExternal>
                <Image
                  borderRadius="full"
                  boxSize="150px"
                  src={val.imageUrl}
                  alt={val.name}
                  mb={2}
                />
              </Link>
            ) : (
              <Link href={val.github} isExternal>
                <Image
                  borderRadius="full"
                  boxSize="150px"
                  src={val.imageUrl}
                  alt={val.name}
                  mb={2}
                />
              </Link>
            )}

            <Text fontWeight="bold" fontSize="xl" color={textColor}>
              {val.name}
            </Text>
            <Badge px={2} py={1} colorScheme="teal" fontWeight="bold">
              {val.position}
            </Badge>
            <HStack spacing={2}>
              {val.github && (
                <Link href={val.github} isExternal>
                  <Text boxSize="6">
                    <i className="bi bi-github"></i>
                  </Text>
                </Link>
              )}
              {val.linkedIn && (
                <Link href={val.linkedIn} isExternal>
                  <Text boxSize="6">
                    <i className="bi bi-linkedin"></i>
                  </Text>
                </Link>
              )}
              {val.twitter && (
                <Link href={val.twitter} isExternal>
                  <Text boxSize="6">
                    <i className="bi bi-twitter-x"></i>
                  </Text>
                </Link>
              )}
            </HStack>
          </VStack>
        ))}
      </SimpleGrid>
    </Box>
  );
}
